.banco-perguntas {
    position: relative;
    margin-top: 50px;
    margin-bottom: 10px;

    &.m-t-0 {
        margin-top: 0;
    }

    @include media-breakpoint-down(sm) {
        border-top: 2px solid $gray-medium;
        padding-top: 20px;
        margin: 10px 0;
    }

    a.bp-title {
        font-size: 22px;
        color: $gray;
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;

        h2 {
            display: inline-block;
            font-weight: bold;
            margin-left: 5px;
            font-size: 24px;
        }

        &.active,
        &:hover {
            color: $orange;
        }
    }

    .bp-encontradas {
        text-transform: uppercase;
        position: absolute;
        font-weight: bold;
        font-size: 12px;
        top: 0;
        right: 0;
        width: 120px;

        @include media-breakpoint-down(lg) {
            text-align: right;
            width: 200px;
            top: 33px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .page-bottom {
        display: flex;
        justify-content: flex-end;

        .bp-encontradas {
            position: relative;
        }
    }
}

.bp-pergunta {
    .buttons {
        position: absolute;
        text-align: center;
        cursor: pointer;
        right: 20px;
        top: 20px;

        a {
            text-decoration: none;
        }

        .adicionada {
            display: inline-block;
            color: $green-light;
            margin-right: 12px;

            i {
                font-size: 35px;

                @include media-breakpoint-down(sm) {
                    font-size: 25px;
                }
            }

            p {
                margin-top: -5px;
                font-size: 13px;

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }

            &:hover {
                color: $orange;
            }
        }

        .excluir {
            display: inline-block;
            color: $red;

            i {
                font-size: 35px;

                @include media-breakpoint-down(sm) {
                    font-size: 25px;
                }
            }

            p {
                margin-top: -5px;
                font-size: 13px;

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }

            &:hover {
                color: $orange;
            }
        }

        .adicionar {
            display: inline-block;

            i {
                background-color: orange;
                border-radius: 25px;
                font-size: 20px;
                color: white;
                padding: 5px;

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }

            p {
                font-size: 13px;
                color: orange;
                margin-top: 3px;

                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }

            &:hover {
                i {
                    background-color: $orange-dark;
                }

                p {
                    color: $orange-dark;
                }

            }
        }
    }

    .bp-footer {
        text-align: right;
        color: $orange;

        i {
            font-size: 19px;
        }

        p {
            display: inline-block;
            margin: 0;
        }

        a {
            color: $orange;
        }
    }
}