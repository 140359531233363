// Years Tabs
.year-tabs {
    position: relative;
}

.nav-tabs {
    border-bottom: 1px solid $gray-light;
    margin-bottom: 20px;
    min-height: 45px;
    
    .nav-item {
        margin-right: 30px;
        cursor: pointer;
    }

    .nav-link {
        border-bottom: 2px solid transparent;
        color: $gray-medium;
        padding: 0.5rem 0;
        font-weight: bold;
        font-size: 17px;
        border: none;

        &.active {
            border-bottom: 2px solid $orange;
            color: $orange;
        }

        &:hover {
            color: $orange;
        }
    }
}

.navigation-tabs {
    position: absolute;
    font-size: 13px;
    top: 15px;
    right: 0;

    span{
        text-decoration: none;
        padding: 0 10px;
    }

    a {
        text-decoration: none;
        padding: 0 10px;
        
        .ti-angle-left {
            color: lightgray;
        }
        
        .ti-angle-right {
            color: lightgray;
        }
        
        .nt-icon {
            font-weight: bold;
        }
    }
}

.active a .ti-angle-right {
    color: orange;
}

.active a .ti-angle-left {
    color: orange;
}

.navigation-tabs-mob {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
        font-size: 13px;
        text-align: center;

        a {
            text-decoration: none;
            padding: 0 10px;
            
            .ti-angle-left {
                color: lightgray;
            }
            
            .ti-angle-right {
                color: lightgray;
            }
            
            .nt-icon {
                font-weight: bold;
            }
        }
    }
}