.sidebar {
    @include transition;
    border-right: 1px solid #E1E0E0;
    background-color: $white-ice;
    min-height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 998;
    width: 300px;
    top: 73px;
    left: 0;
    &.has-view{
        top: 0px;
    }

    @include media-breakpoint-down(md) {
        min-height: auto;
        width: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        height: 60px;
        z-index: 1040;
        &.has-view{
            top: auto;
        }
    }

    .sidebar-header {
        padding: 20px 0 25px;
        text-align: center;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .sidebar-name {
        text-align: center;
        font-weight: bold;
        font-size: 26px;
        margin-top: 5px;
        color: $orange;
    }

    ul.sidebar-navigation {
        margin-right: -1px;
        list-style: none;
        text-align: left;
        padding: 0;

        @include media-breakpoint-down(md) {
            display: flex;
            text-align: center;
            margin: 0;
            height: 100%;
        }
        
        li {
            color: $gray-dark;
            font-size: 18px;

            @include media-breakpoint-down(md) {
                border-top: 3px solid transparent;
                line-height: 0.8em;
                font-size: 11px;
                flex-basis: 0;
                flex-grow: 1;
            }

            a {
                padding: 10px 14px 10px 40px;
                text-decoration: none;
                white-space: break-spaces;
                color: inherit;
                display: block;

                @include media-breakpoint-down(md) {
                    padding: 5px 0;
                    white-space: initial;
                }
                
                @include media-breakpoint-down(xs) {
                    font-size: 10px;
                }

                i {
                    margin-right: 15px;

                    @include media-breakpoint-down(md) {
                        height: 25px;
                        display: block;
                        margin-right: 0;
                        font-size: 2em;
                        margin-bottom: 2px;
                    }
                }

                span {
                    @include transition;
                }

                .sidebar-mob {
                    display: none;

                    @include media-breakpoint-down(md) {
                        display: inline-block;
                    }
                }
            }
            
            &#showFooter {
                display: none;
                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
            
            &:not(#showFooter) {
                &:hover {
                    color: $orange;
                }
            }

            

            .active {
                border-right: 3px solid $orange;
                color: $orange;
                
                @include media-breakpoint-down(md) {
                    border-top: 3px solid $orange;
                    border-right: none;
                }
            }
        }
    }
}

.sidebar-icons {
    width: 100px;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}
