h1, h2, h3, h4, h5, h6 {
  font-family: $font-secondary;
}

.page-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 26px;
  color: $orange;
  margin: 0;

  @include media-breakpoint-down(sm) {
    font-size: 21px;
  }
}

.section-title {
  font-family: $font-primary;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  color: $orange;

  i {
    margin-right: 10px;
  }
}

.section-title span {
  font-family: $font-primary;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  color: $orange;
  margin-left: 10px;
}
