


.form-wrapper {
    min-height: 100vh;
    margin-left: 0;
    width: 50%;

    @include media-breakpoint-down(sm) {
        margin-left: -15px;
        margin-bottom: 0;
        margin-top: 20px;
        min-height: auto;
        padding: 15px;
        width: auto;
    }

    .nav-tabs {
        border-bottom: 1px solid $gray-light;

        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid $orange;
            justify-content: space-between;
            margin-bottom: 30px;
        }

        .nav-item {
            margin-right: 120px;
            margin-bottom: -3px;

            @include media-breakpoint-down(sm) {
                text-align: center;
                margin-right: 0;
                margin-left: 0;
            }

            .nav-link {
                font-family: $font-primary;
                font-weight: bold;
                font-size: 15px;
                padding-left: 0;
                color: $gray;

                @include media-breakpoint-down(sm) {
                    color: $orange-medium;
                    font-size: 20px;
                }

                &.active,
                &:hover {
                    border-bottom: 3px solid $orange;
                    color: $orange;

                    @include media-breakpoint-down(sm) {
                        background-color: transparent;
                        color: $orange-light;
                        border-bottom: 2px solid $orange-light;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }

    .form-container {
        padding: 75px 100px;
        max-width: 800px;

        @include media-breakpoint-down(lg) {
            padding: 75px 50px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 20px;
        }

        .btn {
            margin-top: 40px;
        }
    }
    
    p {
        margin-top: 30px;
        font-family: $font-primary;
        color: $gray-dark;
    }

    a {
        font-weight: bold;
        color: inherit;
    }

    .terms {
        font-family: $font-primary;
        text-align: center;
        color: $gray-dark;
        margin: 5px 0 0 0;
        font-size: 14px;

        @include media-breakpoint-down(sm) {
            color: $white;
            margin: 5px 0 10px 0;
        }
    }
}

.cover-wrapper {
    position: fixed;
    z-index: -1;
    bottom: 0;
    left: 50%;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
    gap: 5px;


    @include media-breakpoint-down(sm) {
        left: 0;
    }
    
    &:after {
        content: '';
        background-color: #f2f2f2;
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    .brand {
        img {
            width: 17vw;
        }
        // transform: translateX(-50%);
        // position: absolute;
        // top: 100px;
        // left: 50%;

        // @include media-breakpoint-down(sm) {
        //     top: 50px;
        //     width: 100%;
        // }
    }

    .cover-title {
        font-family: $font-secondary;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 30px;
        font-size: 20px;
        color: $white;
        white-space: nowrap;
    }

    .imgOlimpiadas {
        img {
            width: 37vw;
        }
    }

    a.sm-brand {
        // position: absolute;
       // margin-left: -35px;
        // bottom: 100px;
        // width: 100%;
        // display: flex;
        // justify-content: center;
        //left: 50%;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        img {
            width: 7vw;
        }
    }
}

.brand-mob {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;

        img {
            width: 70vw;
            margin-top: 30px;
        }

        .cover-title {
            font-family: $font-secondary;
            text-transform: uppercase;
            font-weight: bold;
            margin-top: 15px;
            font-size: 17px;
            color: $white;
            margin-bottom: 0;
        }
    }
}

a.sm-brand-mob {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;

        img {
            display: block;
            width: 20vw;
            height: auto;
            margin: 40px auto;
        }
    }
}

