.filter {
    margin-top: 30px;
    
    i {
        font-weight: bold;
        font-size: 20px;
        color: $orange;
    }
    
    h3 {
        display: inline-block;
        font-weight: bold;
        margin-left: 5px;
        margin-bottom: 0;
        font-size: 24px;
        color: $orange;
    }
    
    .btn-clean {
        margin-bottom: 5px;
        padding: 4px 15px;
        margin-left: 10px;
        min-width: auto;
        color:#9A9A9A;
    }

    .sort-bar {
        p {
            margin-bottom: 15px;
            font-size: 15px;
        }
        
        span {
            width: 100% !important;
            padding: 5px 0;
        }
    }

    .search-group {
        position: relative;
        margin-top: 30px;

        .form-control {
            margin: 0 !important;
            font-weight: normal;
            width: 100%;
        }
        
        .btn {
            background-color: transparent;
            position: absolute;
            min-width: auto;
            color: $orange;
            padding: 7px;
            right: 0;
            top: 0;

            i {
                font-weight: 100;
                font-size: 37px;

                @include media-breakpoint-down(md) {
                    font-size: 29px;
                }
            }
        }
    }
    &.my-questions-filter {
        & {
            .search-group {
                margin-top: -25px;
            }
        }
    }
}

.open-filter {
    display: none;

    @include media-breakpoint-down(sm) {
        display: block;
        float: right;
        margin: 6px;
        text-decoration: none;

        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
        }
    }
}
