.config-title {
    color: $orange;
    
    h2 {
        display: inline-block;
        margin: 0 0 20px 10px;
        font-weight: bold;
        font-size: 18px;
    }
}

.config-items {
    .col,
    .col-sm-2 {
        padding-right: 0;
    }
}