.card-reports {
    .card-title {
        margin-top: 30px;
    }
    
    .cr-wrapper {
        margin-top: 30px;
        
        .report-title {
            text-transform: uppercase;
            margin-bottom: 20px;
            font-weight: bold;
        }
        
        .report-item {
            text-decoration: none;
            cursor: pointer;
        }
        
        i {
            position: relative;
            color: #C4C8C9;
            font-size: 50px;
            
            &:after {
                background-color: orange;
                border-radius: 10px;
                position: absolute;
                height: 10px;
                content: '';
                width: 10px;
                right: -4px;
                top: 2px;
            }
            
            &.status-agendada:after {
                background-color: #FF9A10;
            }
            
            &.status-incompleta:after {
                background-color: #EB3F00;
            }
            
            &.status-aplicada:after {
                background-color: #56F700;
            }
            
            &.status-finalizada:after {
                background-color: #0FD0A3;
            }
        }
    }
    
    .report-name {
        color: #8E8E8E;
        font-size: 14px;
    }
}
