.sticky-top {
    top: 100px;
}

.rsbtn {
    width: 380px!important;
    display: flex;
    justify-content: flex-end;
}

.card {
    @include transition;
    box-shadow: $shadow-default;
    border: none;
    margin-bottom: 30px;
    display: flex;

    &.card-assessment {
        height:100%;

        .cm-icon {
            font-size: 2.6vw;
            color: #C6C1BC;
            line-height: 1em;

            @include media-breakpoint-down(md) {
                font-size: 50px;
            }
        }
    }

    &.card-materia-list {
        height: inherit;

        .cm-icon {
            font-size: 1.8vw;

            @include media-breakpoint-down(md) {
                font-size: 50px;
            }
        }

       
    }
    
    &:hover {
        box-shadow: $shadow-hover;
    }

    &.card-highlight {
        text-align: center;

        .highlight-title {
            text-align: left;
            line-height: 1em;
            font-weight: bold;
            color: #867A65;

            label {
                margin-bottom: 0;
            }

            span {
                font-weight: normal;
                display: block;
            }
        }

        .highlight-number {
            font-size: 60px;
            font-weight: bold;
            color: #919191;
            margin: 1.25rem 0;
        }
    }

    .cm-icon {
        font-size: 70px;
        color: #C6C1BC;
        line-height: 1em;

        @include media-breakpoint-down(lg) {
            font-size: 50px;
            display: inline-block;
        }
    }

    .card-icon {
        background: #E4E4E4;
        border-radius: 100%;
        width: 70px;
        height: 70px;
        color: #FFFFFF;
        font-size: 45px;
        line-height: 70px;
        text-align: center;
    }

    .card-title {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 22px;
        margin: 0;
    }

    .card-title-alt {
        color: $orange;
        text-align: left;
        font-weight: bold;
        font-size: 18px;

        i {
            display: inline;
        }
    }

    .card-bottom-text {
        line-height: 1.5rem;
        margin-bottom: 0;
    }

    .card-more {
        border-top: 1px solid $gray-light;
        text-decoration: none;
        display: block;
        color: $gray-dark;
        font-size: 14px;
        margin: 20px -10px -20px;
        padding: 10px;
        cursor: pointer;
    }

    .fixed-actions {
        position: absolute;
        text-align: center;
        line-height: 1rem;
        padding: 10px;
        right: 0;
        top: 0;
        
        i {
            font-size: 1.2rem;
            display: block;
        }

        a {
            text-decoration: none;
            display: inline-block;
            font-size: 0.7rem;
            color: $orange;
            cursor: pointer;
        }
    }
}

// card school
.card-school {
    text-align: center;

    .card-icon {
        margin: 30px 0 40px;
    }

    .card-description {
        margin-bottom: 40px;
    }
}

.card-professors {
    margin-bottom: 20px;

    .card-body {
        padding: 0.75rem 1.25rem;
    }

    .card-icon {
        width: 50px;
        height: 50px;
        font-size: 40px;
        line-height: 50px;
    }

    .card-description {
        margin-bottom: 0;
    }
}

// Card Menu
.card-menu {
    text-align: center;
    position: absolute;
    color: $gray-light;
    font-size: 16px;
    right: 0px;
    top: 5px;
    width: 30px;
    height: 30px;
    padding-top: 4px;
    z-index: 10;
    cursor: pointer;

    &:hover {
        color: $orange;
        .cm-floating {
            transform: scale(1);
        }
    }

}

.cm-floating {
    @include transition;
    transform-origin: 100% 25%;
    position: absolute;
    transform: scale(0);
    background-color: $white-ice;
    border-radius: 3px;
    right: 100%;
    top: -50%;
    z-index: 1;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
    will-change: filter;

    &:after {
        content: "";
        width: 30px;
        height: 24px;
        background-color: $white-ice;
        position: absolute;
        right: -3px;
        top: 15px;
        transform: rotate(45deg);
        z-index: -1;
    }
    
    a {
        display: block;
        font-size: 10px;
        color: $gray-medium;
        text-decoration: none;
        padding: 6px 8px;
        
        &:hover {
            color: $orange;
        }
        
        i {
            display: block;
            font-size: 20px;
            transform: rotate()
        }
    }
}

// Card Adicionar Avaliação
.card.add-new {
    background-color: $white-ice;
    text-align: center;
    box-shadow: none;
    border: 1px solid $gray-light;
    display: flex;
    flex-flow: row;
    align-items: center;
    text-decoration: none;

    &:hover {
        box-shadow: none;
        border: 1px solid $gray;
    }

    span {
        width: 100%;
        font-size: 17px;
        line-height: 24px;
        padding: 22px 0;
        color: $gray-dark;
        text-decoration: none;

        @include media-breakpoint-down(lg) {
            color: $orange;
        }
        
        .add-icon {
            color: $white;
            background-color: $orange;
            width: 40px;
            height: 40px;
            margin: auto;
            padding: 9px 11px;
            border-radius: 30px;
            margin-bottom: 5px;

            @include media-breakpoint-down(lg) {
                background-color: transparent;
                color: $orange;
                border: 1px solid $orange;
            }
        }
    }
}

.card-list {
    a {
        padding: 45px 20px;
        
        .add-icon {
            position: absolute;
            margin: 0;
            top: 50%;
            transform: translateY(-50%);
            left: 25px;
        }
    }
}

// Card Agendamento
.card-materia {
    .card-body {
        @include media-breakpoint-down(lg) {
            padding: 1rem;
        }

        .btn-outline-warning {
            margin-top: 30px;
            font-size: 13px;
            padding: 2px 20px;
        }
    }
}

// Card Avaliação
.card-question {
    padding: 25px;

    .badge {
        width: 50px;
        padding: 13px;
        font-size: 13px;
    }

    .subtitle-question {
        font-weight: bold;
        font-size: 20px;
        font-family: $font-primary;
        position: relative;
        margin: 0px 12px 7px 12px;
    }
    
    .question {
        margin-top: 30px;
        
        p {
            font-size: 17px;
            color: #000; /*#9B9B9B */
            max-width: 80%;
        }

        img{
            max-width:100%;
        }

        @media (max-width: 1280px) {
            img{
                max-width:100%;
                height: auto !important;
                width: auto !important;
            }
        }
        

    }
    .options {
        img{
            max-width:100%;
        }

        @media (max-width: 1280px) {
            img{
                max-width:100%;
                height: auto !important;
                width: auto !important;
            }
        }
    }
    
    .custom-control-input:checked + label {
        color: $orange-light;
    }

    // .custom-control-input + label {
    //     span {
    //         margin-right: 10px;
    //     }
    // }
}

// Card Time
.card-time {
    padding: 20px;
    
    .info-time {
        text-align: center;
        color: $orange;
        font-size: 15px;
        font-weight: bold;
        
        i {
            margin-right: 10px;
        }
    }
    
    .remaining {
        margin-top: 20px;
        background-color: $white-ice;
        text-align: center;
        border-radius: 5px;
        
        p {
            color: $gray-dark;
            font-size: 13px;
            margin-bottom: -10px;
            padding-top: 5px;
        }
        
        span {
            font-size: 30px;
            color: $orange;
            font-weight: bold;
        }
    }
    
    .btn {
        margin-top: 50px;
        padding: 10px;
    }
}

// Card Perguntas adicionadas
.card-adicionadas {
    padding: 15px 15px 0 15px;

    @include media-breakpoint-down(md) {
        display: none;
    }
    
    .card-title {
        font-size: 15px;
        color: $orange;
        
        i {
            margin-right: 10px;
        }
        
        .card-open {
            font-weight: bold;
            padding-top: 4px;
            float: right;
        }
    }
    
    .card-info {
        text-align: right;
        color: darkgray;
        
        p {
            margin: 0;
        }
        
        .card-info-number {
            color: darkorange;
            font-weight: bold;
            margin-top: -10px;
            font-size: 28px;
        }
    }
    
    .card-nav {
        margin: 15px 0;
    }

.card-questions {
    display: inline-block;

    .badge-group{
        position: relative;
    }

    .question-badge-btn-close {
        transition: transform 100ms ease;
        background-color: $orange;
        border-radius: 10px;
        transform: scale(0);
        position: absolute;
        color: #FFFFFF;
        font-size: 18px;
        right: -6px;
        z-index: 1;
        top: -8px;
        cursor: pointer;
    }

    .badge-group:hover .question-badge-btn-close{
        transform: scale(1);
        color: #FFFFFF;
    }
    
    .question-badge {
        background-color: #f2f2f2;
        color: #9A9A9A;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 3px 7px;
        position: relative;
        text-align: center;
        border-radius: 5px;
        font-weight: bold;
        line-height: 1rem;
        padding-top: 17px;
        cursor: pointer;
        font-size: 13px;
        height: 50px;
        width: 50px;

    }

}
    
    .btn {
        padding: 5px;
        min-width: auto;
        margin-bottom: 15px;
    }
    
    .col-sm-6:first-child {
        padding-left: 15px;
        padding-right: 5px;
    }
    
    .col-sm-6:last-child {
        padding-right: 15px;
        padding-left: 5px;
    }

    
}

.card-adicionadas-mob {
    display: none;

    @include media-breakpoint-down(md) {
        margin-top: 80px;
        padding: 5px 15px;
        background-color: $orange-dark;
        display: block;
        border-radius: 0;
        
        .card-title {
            font-size: 16px;
            color: $white;
            font-weight: normal;
            display: inline-block;

            i {
                margin-right: 10px;
            }
        }
    }


    @include media-breakpoint-down(sm) { 
        .card-title {
            font-size: 14px;
        }    
    }
}


.adicionadas-content-mob { 
    display: none;


    @include media-breakpoint-down(md) {
        position: absolute;
        // top: 110px;
        left: 0;
        right: 0;
        z-index: 2;
        margin: 0 15px;
        padding: 30px 15px 15px 15px;
        text-align: center;
        @include transition;
        transform: translateY(-1000px);
        display: block;

        .btn {
            @include media-breakpoint-down(xs) { 
                font-size: 13px;
            }
        }

        &.active {
            transform: translateY(-135px);
        }

        .card-nav{
            text-align: left;
        }

        .card-questions {
            display: inline-block;
        
            .badge-group{
                position: relative;
            }
        
            .question-badge-btn-close {
                transition: transform 100ms ease;
                background-color: $orange;
                border-radius: 10px;
                transform: scale(0);
                position: absolute;
                color: #FFFFFF;
                font-size: 18px;
                right: -6px;
                z-index: 1;
                top: -8px;
                cursor: pointer;
            }
        
            .badge-group:hover .question-badge-btn-close{
                transform: scale(1);
                color: #FFFFFF;
            }

            .badge-group:active  .question-badge-btn-close{
                transform: scale(0);
            }
            
            .question-badge {
                background-color: #f2f2f2;
                text-transform: uppercase;
                display: inline-block;
                margin: 0 3px 7px;
                position: relative;
                text-align: center;
                border-radius: 5px;
                font-weight: bold;
                line-height: 1rem;
                padding-top: 11px;
                cursor: pointer;
                font-size: 13px;
                height: 40px;
                width: 40px;
        
            }
        
        }
    }


}

// Prévia
.card-previa {
    padding: 20px;
}

.card-relatorio-aluno {
    padding-right: 30px;

    .card-body {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .bar-chart {
        margin-top: 35px;
    }
}

.card-adjust {
    flex-flow: row wrap;
    width: 100%;
    display: flex;
}

.card-deck .card{
    flex: 1 1 auto !important;
    height: 100%;
    margin: 0;
}

.card-adjust {
    .row{
        display: flex;
        flex-wrap: wrap;
        margin-right: 0;
        margin-left: 0;
        width:100%
    }
    .col-lg-4{
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {

    .card-deck {
        flex-flow: row wrap!important;
    }

    .card-adjust {
        display: inline;
    }
}

.small-cards .card-highlight{
    min-height: 210px;
}

.big-cards .card-highlight{
    min-height: 297px;
}