* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // @include media-breakpoint-down(sm) {
    //     user-select: none;
    // }
}

// .force-selection {
//     user-select: all !important;
// }

html {
    // overflow: hidden;
}

body {
    font-family: $font-primary;
    // overflow: hidden;
    color: #9A9A9A;
    font-size: 16px;
    // height: 100vh;

    // rules
    &:before,
    &:after {
        pointer-events: none;
        background: blue;
        position: fixed;
        z-index: 999999;
        // content: '';
    }

    // vertical rule
    &:before {
        height: 100%;
        left: 60px;
        width: 1px;
    }

    // horizontal rule
    &:after {
        width: 100%;
        height: 1px;
        top: 130px;
    }
}

.content-wrapper {
    background-color: #FDFDFD;
    padding-bottom: 100px;
    padding-left: 350px;
    padding-right: 70px;
    padding-top: 130px;
    position: relative;
    // overflow: auto;
    // height: 100%;
    &.has-view{
        padding-top: 30px;
    }

    @include media-breakpoint-down(md) {
        padding: 100px 15px 100px 15px;
        &.has-view{
            padding-top: 20px;
        }
    }

    &--expanded {
        padding-left: 150px;

        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }

    .margin-bottom {
        margin-bottom: 50px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
        }
    }
}

.loadmore,
.load-more {
    text-align: center;
    color: $gray-dark;
    font-size: 15px;
    display: block;
    padding: 5px 0;
    
    &:hover {
        text-decoration: none;
        color: $orange;
    }
}

.link-info {
    cursor: pointer;
    font-size: 14px;

    i {
        transform: translateY(3px);
        font-size: 1.5em;
    }
}

.tooltip {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
    opacity: 1 !important;
    width: 250px;

    .tooltip-inner {
        background-color: #F8F8F8;
        transform: translateY(-25%);
        padding: 15px 20px;
        text-align: left;
        color: #9A9A9A;
        max-width: 240px;
        opacity: 1;

        .tooltip-heading {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
            color: $orange;
        }

        .tooltip-item {
            margin-bottom: 10px;
            font-size: 12px;

            b{
                font-weight: bold;
            }
        }
    }

    .arrow {
        &:before {
            border-left-color: #F8F8F8;
        }
    }
}

.align-center {
    display: inline-block;

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
        color: $gray-dark;
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.display-none {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.display-block {
    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.margin-mob {
    @include media-breakpoint-down(md) {
        margin: 0 !important;
    }
}

.margin-top {
    @include media-breakpoint-down(lg) {
        margin-top: 30px !important;
    }
}

.mr-mob {
    @include media-breakpoint-down(sm) {
        margin-right: 5px !important;
    }
}

.border-none {
    @include media-breakpoint-down(sm) {
        border: none !important;
    }
}

.pr-mob {
    @include media-breakpoint-down(md) {
        padding-right: 0;
    }
}

.p-mob {
    @include media-breakpoint-down(xs) {
        padding: 0;
    }
}

/* Absolute Center Spinner */
.loading {
    display: none;
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &.show { display: block; }

    /* Transparent Overlay */
    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
    }

    /* :not(:required) hides these rules from IE9 and below */
    &:not(:required) {
        /* hide "loading..." text */
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;

        &:after {
            content: '';
            display: block;
            font-size: 10px;
            width: 1em;
            height: 1em;
            margin-top: -0.5em;
            -webkit-animation: spinner 1500ms infinite linear;
            -moz-animation: spinner 1500ms infinite linear;
            -ms-animation: spinner 1500ms infinite linear;
            -o-animation: spinner 1500ms infinite linear;
            animation: spinner 1500ms infinite linear;
            border-radius: 0.5em;
            -webkit-box-shadow: rgba(255, 255, 255, 0.8) 1.5em 0 0 0, rgba(255, 255, 255, 0.8) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) 0 1.5em 0 0, rgba(255, 255, 255, 0.8) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) -1.5em 0 0 0, rgba(255, 255, 255, 0.8) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.8) 0 -1.5em 0 0, rgba(255, 255, 255, 0.8) 1.1em -1.1em 0 0;
            box-shadow: rgba(255, 255, 255, 0.8) 1.5em 0 0 0, rgba(255, 255, 255, 0.8) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) 0 1.5em 0 0, rgba(255, 255, 255, 0.8) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.8) -1.5em 0 0 0, rgba(255, 255, 255, 0.8) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.8) 0 -1.5em 0 0, rgba(255, 255, 255, 0.8) 1.1em -1.1em 0 0;
        }

    }

}
  

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

// .alert {
//     display: none;
//     visibility: hidden;
//     opacity: 0;
//     transition: visibility 0s, opacity 0.5s linear;
//     &.alert-danger, &.alert-warning, &.alert-success, &.alert-info {
//         display: block;
//         visibility: visible;
//         opacity: 1;
//     }
// }

.gap{
    height:100px;
 }

 @media (max-width: 575px) { 
    .gap{
        height:30px;
     }
 }

 .modal-footer.assessment{
     padding:20px;
     display: block; 

    .select2-container {
        width: 100% !important;
        margin-right: 20px;
        margin-bottom: 0px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }

        .select2-selection__arrow b {
            right: 10px;
            left: auto;
        }
    }
    
    .select2-selection {
        padding: 0.2rem;
        
        .select2-selection__arrow {
            height: 75px;
            
            b {
                border-color: orange transparent transparent transparent;
                border-width: 7px 6px 0 6px;
                margin-left: -6px;
                margin-top: -3px;
            }
        }
    }
    .select2-container--default .select2-selection--single {
        padding: 1.3rem;
    }
 }

 .width100{
     width:100%;
 }

 .line{
    border-bottom: 1px solid #dfdfdf;
 }

 .gap50{
     height:50px;
     width: 100%;
 }

 .linkable{
     cursor: pointer;
 }

 .opacity-0{
     opacity: 0 !important;
 }

 .invisible{
     visibility: hidden;
 }

 a.disabled{
     cursor: not-allowed;
 }