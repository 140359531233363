.avaliacao {
  .page-title {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .info-materia {
    color: #867A65;
    font-size: 15px;
    i {
      margin-right: 5px;
    }
  }
  .instruction {
    font-weight: bold;
    margin-left: -1px;
    margin-top: 50px;
    font-size: 17px;
    color: $orange;
    i {
      margin-right: 5px;
    }
    p {
      border-bottom: 1px solid #E5E5E5;
      padding-bottom: 30px;
      margin-bottom: 40px;
      color: $gray-dark;
      margin-top: 10px;
      font-size: 15px;
    }
  }
}

.enunc {
  position: relative;
  font-size: 16px;
  color: #000;
  left: 60px;
  top: -40px;
  width: 90%;
  margin: 0;
  text-align: justify;
  p {
    margin: -15px 0 0 0px !important;
    padding: 15px 0
  }


  @include media-breakpoint-down(sm) {
    width: 80%;
  }
}

.enunc-alt {
  margin: 25px 0;
  color: #000!important;
  p {
    // color: #9A9A9A !important;
    margin: 10px 0 0 10px !important;
    padding: unset !important
  }
  span {
    color: #000!important;
  }
}

.liNumber {
  li {
    list-style: decimal!important;
  }
}
