.change-avatar {
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    width:220px;
    height:220px;
    border: 2px solid #F78E00;

    .ca-button {
        background-color: rgba(112, 112, 112, 0.5);
        font-family: $font-secondary;
        transform: translateZ(0);
        position: absolute;
        text-align: center;
        font-weight: bold;
        line-height: 0.6;
        font-size: 15px;
        display: block;
        color: white!important;
        bottom: 0;
        right: 0;
        top: 60%;
        left: 0;
        
        i.pe-7s-camera {
            @include transition;
            font-size: 45px;
            display: block;
        }
        
        &:hover {
            text-decoration: none;

            i.pe-7s-camera {
                transform: scale(0.9);
            }
        }
    }

    input[type=file] {
        display: none;
    }

    img {
        width:auto;
        height:100%;
    }

    
}

.upload-logo{
    position: relative;
}


.photo-btn-close {
    background-color: #F78E00!important;
    border-radius: 30px;
    border:2px solid #fff;
    position: absolute;
    color: #FFFFFF !important;
    font-size: 28px;
    left: 20px;
    top: 30px;
    z-index: 1;
    cursor: pointer;
    transform: scale(1);
    transition: transform 100ms ease;
}

.logo-btn-close {
    background-color: #F78E00!important;
    border-radius: 30px;
    border:2px solid #F8F8F8;
    position: absolute;
    color: #FFFFFF !important;
    font-size: 28px;
    left: -15px;
    top: 0px;
    z-index: 1;
    cursor: pointer;
    transform: scale(1);
    transition: transform 100ms ease;
}

.avatar-profile {
    background-color: $orange-dark;
    border-radius: 50px;
    text-align: center;
    position: relative;
    height: 50px;
    margin: auto;
    width: 50px;
    
    .avatar-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 50px;
    }
    
    .avatar-country {
        text-transform: uppercase;
        background-color: $green;
        border-radius: 100px;
        position: absolute;
        font-weight: bold;
        color: #F5FF00;
        font-size: 14px;
        padding: 2px 0;
        height: 25px;
        width: 25px;
        left: -10px;
        top: 0;
    }
}

.avatar {
    background-color: $gray-light;
    display: inline-block;
    border-radius: 50px;
    text-align: center;
    position: relative;
    color: #ffffff;
    font-size: 35px;
    height: 70px;
    width: 70px;

    &:before {
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &.avatar-sm {
        height: 60px;
        width: 60px;
    }
}

.user-photo{
    margin-bottom: 50px;
}
