.navbar {
    background-color: $orange;
    padding: 20px 45px;
    position: fixed;
    z-index: 1049;
    height: auto;
    width: 100%;

    @include media-breakpoint-down(md) {
        padding: 20px 0;
        height: 80px;
    }

    .hamburger {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            background: none;
            border: none;
            position: absolute;
            right: 10px;
            cursor: pointer;
            color: #fff;
            
            path {
                fill: #fff !important;
            }
        }
    }
    
    .navbar-brand {
        padding: 0;
        margin: 0;
        
        img {
            height: auto;
            width: 65px;

            @include media-breakpoint-down(md) {
                width: 65px;
            }
        }
    }
    
    .navbar-content {  
        border-left: 1px solid white;
        padding-left: 20px;
        margin-left: 20px;

        @include media-breakpoint-down(md) {
            border: none;
            padding-left: 0;
            margin-left: 0;
        }
    }
    
    img.navbar-icon {
        height: 32px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .navbar-title {
        font-family: $font-secondary;
        text-transform: uppercase;
        vertical-align: middle;
        margin-left: 20px;
        font-weight: bold;
        font-size: 20px;
        color: $white;

        @include media-breakpoint-down(md) {
            font-size: 15px;
            display: block;
            width: 160px;
        }
    }

    .user-dropdown {
        position: relative;
        color: #fff;
        z-index: 99;

        @include media-breakpoint-down(md) {
            @include transition;
            transform: translateX(100%);
            background-color: #F08B03;
            padding: 40px 20px;
            position: fixed;
            left: 35%;
            right: 0;
            top: 80px;
            bottom: 60px;
    
            &:after {
                @include transition;
                content: '';
                pointer-events: none;
                position: fixed;
                background: #6a5d5d;
                width: 100vw;
                opacity: 0;
                top: 0;
                right: 100%;
                bottom: 0;
            }

            &.active {
                transform: translateX(0);

                &:after {
                    opacity: 0.4;
                    pointer-events: all;
                }
            }
        }

        .ud-greeting {
            vertical-align: middle;
            display: inline-block;
            margin-right: 25px;
            line-height: 1.2em;
            text-align: right;
            font-size: 14px;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .ud-username {
            font-weight: bold;
        }

        .ud-link {
            text-decoration: none;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            i, svg {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

        .ud-dropdown {
            vertical-align: middle;
            display: inline-block;
            margin: -10px 0;

            @include media-breakpoint-down(md) {
                display: block;
                margin: 0;
            }

            @include media-breakpoint-down(xs) {
                margin: -10px 0;
            }
    
            .ud-menu-wrapper {
                @include transition;
                transform: translateY(-10px);
                pointer-events: none;
                position: absolute;
                padding-top: 15px;
                opacity: 0;
                top: 100%;
                right: 0;

                @include media-breakpoint-down(md) {
                    opacity: 1;
                    transform: translateY(0);
                    pointer-events: all;
                    position: relative;
                    margin-top: 20px;
                    padding: 0;
                }
            }

            &:hover,
            &.active {
                .ud-menu-wrapper {
                    transform: translateY(0);
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }

        ul.ud-menu {
            background-color: $orange-dark;
            border-radius: 3px;
            list-style: none;
            overflow: hidden;
            padding: 0;
            
            @include media-breakpoint-down(md) {
                background-color: transparent;
                text-align: center;
            }

            li {
                a {
                    text-decoration: none;
                    padding: 5px 20px;
                    font-size: 14px;
                    display: block;
                    color: #fff;

                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                        padding: 10px 20px;
                    }
                    
                    &:hover {
                        background-color: orange;
                    }
                }
            }
        }
    }

    .mobile-username {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            font-weight: bold;
        }
    }

    .btn.mobile-button {
        display: none;
        
        @include media-breakpoint-down(md) {
            font-weight: normal;
            display: inline-block;
            padding: 0 15px;
            font-size: 13px;
            opacity: 0.8;
        }
    }

    .avatar-profile {
        display: inline-block;

        @include media-breakpoint-down(md) {
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.1);
            width: 80px;
            height: 80px;
        }
    }

    .svg-inline--fa {
        margin-left: 5px;

        path {
            fill: #FFC400;
        }
    }
}
