.steps {
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    z-index: 1;

    .step {
        .step-number {
            @include transition;
            border: 2px solid $gray-light;
            background-color: #fff;
            border-radius: 20px;
            color: $gray-light;
            padding-top: 1px;
            height: 30px;
            width: 30px;
            margin: auto;

            i.ti-check {
                display: none;
            }
        }

        span {
            @include transition;
            color: $gray-light;
            font-size: 12px;
        }
    }

    &:after,
    &:before {
        position: absolute;
        content: " ";
        height: 2px;
        left: 20px;
        top: 15px;
    }
    
    &:after {
        background-color: $gray-light;
        right: 20px;
        z-index: -1;
    }

    &:before {
        transition: right 500ms ease;
        background-color: $orange;
        z-index: 0;
    }

    @for $i from 1 through 100 {
        &.step-#{$i} {
            // current step
            .step:nth-child(#{$i}) {
                .step-number {
                    background-color: $orange-light;
                    border: 2px solid $orange-light;
                    color: #ffffff;
                }
                
                span {
                    color: $orange-light;
                }
            }

            // done steps
            .step:nth-child(#{'-n+' + ($i - 1)}) {
                .step-number {
                    background-color: $orange-dark;
                    border: 2px solid $orange-dark;
                    color: #ffffff;
                    font-size: 0;
        
                    i.ti-check {
                        font-weight: bold;
                        font-size: 14px;
                        margin-top: 5px;
                        display: block;
                    }
        
                    &:after {
                        border-bottom: 2px solid $orange-dark !important;
                    }

                    &.step-back {
                        cursor: pointer;
                    }
                }
        
                span {
                    color: $orange-dark;
                }
            }

            &:before {
                right: #{(100 - (33 * ($i - 1))) + '%'};
            }
        }
    }
}

.only-two-steps {
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    z-index: 1;

    .step {
        .step-number {
            @include transition;
            border: 2px solid $gray-light;
            background-color: #fff;
            border-radius: 20px;
            color: $gray-light;
            padding-top: 1px;
            height: 30px;
            width: 30px;
            margin: auto;

            i.ti-check {
                display: none;
            }
        }

        span {
            @include transition;
            color: $gray-light;
            font-size: 12px;
        }
    }

    &:after,
    &:before {
        position: absolute;
        content: " ";
        height: 2px;
        left: 20px;
        top: 15px;
    }
    
    &:after {
        background-color: $gray-light;
        right: 20px;
        z-index: -1;
    }

    &:before {
        transition: right 500ms ease;
        background-color: $orange;
        z-index: 0;
    }

    @for $i from 1 through 2 {
        &.step-#{$i} {
            // current step
            .step:nth-child(#{$i}) {
                .step-number {
                    background-color: $orange-light;
                    border: 2px solid $orange-light;
                    color: #ffffff;
                }
                
                span {
                    color: $orange-light;
                }
            }

            // done steps
            .step:nth-child(#{'-n+' + ($i - 1)}) {
                cursor: pointer;
                .step-number {
                    background-color: $orange-dark;
                    border: 2px solid $orange-dark;
                    color: #ffffff;
                    font-size: 0;
        
                    i.ti-check {
                        font-weight: bold;
                        font-size: 14px;
                        margin-top: 5px;
                        display: block;
                    }
        
                    &:after {
                        border-bottom: 2px solid $orange-dark !important;
                    }
                }
        
                span {
                    color: $orange-dark;
                }
            }

            &:before {
                right: #{(100 - (99 * ($i - 1))) + '%'};
            }
        }
    }
}
