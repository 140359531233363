$chart-height: 150;
$chart-padding: 30;

.bar-chart {
    position: relative;

    .legends {
        text-align: left;

        .legend {
            .legend-color {
                background-color: $gray;
                display: inline-block;
                margin-bottom: -2px;
                border-radius: 4px;
                margin-right: 10px;
                height: 1em;
                width: 1em;
            }

            .legend-text {
                color: $orange;
            }
        }
    }

    .bar-chart-wrapper {
        margin-top: #{$chart-padding / 2}px;
        position: relative;
        flex-wrap: wrap;
        display: flex;
    }

    .bc-number {
        transform: translateX(-50%);
        margin-left: 1px;
        margin-top: 10px; 
        font-size: 14px;
        display: block;
        color: $orange;
        float: left;
    }

    // colors
    .bc {
        &--yellow {
            background-color: $orange-light !important;
        }

        &--orange {
            background-color: $orange !important;
        }
    }

    &.horizontal-bars-chart {
        .bar-chart-wrapper {
            border-left: 1px solid #EBEBEB;
            padding: 10px 30px 20px 0;
            flex-flow: column;
        }

        .bc-row {
            justify-content: flex-start;
            position: relative;
            flex-wrap: wrap;
            padding: 10px 0;
            display: flex;

            p {
                text-align: left;
                width: 100%;
            }

            .bc-bar {
                border-bottom-right-radius: 6px;
                transition: width 700ms ease;
                border-top-right-radius: 6px;
                box-shadow: $shadow-default;
                background-color: $orange;
                position: relative;
                height: 15px;
                width: 0;
                max-width: 100%;

                .bc-bar-value {
                    transform: translateY(-50%);
                    position: absolute;
                    line-height: 1em;
                    margin-top: -1px;
                    margin-left: 5px;
                    font-size: 14px;
                    color: $orange;
                    left: 100%;
                    top: 50%;
                }
            }
        }
    }

    &.vertical-bars-chart {
        padding: #{$chart-padding}px 0;
        
        .bar-chart-wrapper {
            height: #{$chart-height - ($chart-padding / 2)}px;
            border-bottom: 1px solid #EBEBEB;
            justify-content: space-between;
            height: #{$chart-height}px;
            align-items: flex-end;
        }

        .bc-column {
            height: #{$chart-height - ($chart-padding / 2)}px;
            justify-content: flex-end;
            text-align: center;
            position: relative;
            flex-flow: column;
            flex-wrap: wrap;
            padding: 0 10px;
            display: flex;

            .bc-bar {
                transition: height 700ms ease;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
                box-shadow: $shadow-default;
                background-color: $orange;
                position: relative;
                margin: 0 auto;
                width: 15px;
                height: 0;
                max-height: 100%;

                .bc-bar-value {
                    filter: drop-shadow(0 2px 4px rgba(black, 0.15));
                    transform-origin: bottom center;
                    background-color: #F8F8F8;
                    will-change: transform;
                    @include transition;
                    margin-bottom: 10px;
                    transform: scale(0);
                    position: absolute;
                    border-radius: 5px;
                    text-align: center;
                    margin-left: -22px;
                    font-size: 16px;
                    color: $orange;
                    padding: 2px 0;
                    bottom: 100%;
                    width: 44px;
                    z-index: 5;
                    left: 50%;

                    &:after {
                        transform: rotate(45deg) translateX(-50%) translateY(25%);
                        background-color: inherit;
                        position: absolute;
                        height: 10px;
                        bottom: -6px;
                        content: '';
                        width: 10px;
                        left: 50%;
                    }
                }
            }

            &:hover {
                .bc-bar-value {
                    transform: scale(1);
                }
            }

            .bc-description {
                margin-bottom: -28px;
                padding-top: 10px;
                font-size: 12px;
                color: $orange;
            }
        }
    }

    &.no-border {
        .bar-chart-wrapper {
            border: none;
            
            p {
                text-align: left;
                font-size: 14px;
                margin: 0;
            }
        }
    }

    &.bar-chart-compact {
        .bar-chart-wrapper {
            padding-bottom: 0;
            padding-top: 0;
            margin-top: 0;
        }
    }
}